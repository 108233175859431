html {
  background-color: #FFFFFF;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
  padding: 0;
  margin: 0;
}
:root {
  --text-primary-color: #D9D9DA;
  --heading-primary-color: #FFFFFF;
  --bg-primary-color: #FCFEFF;
  --link-primary-color: #FDA070;
  --button-primary-color: #F4A37E;
  --circle-orange-color: #EDBEA5;
  --circle-blue-color: #BEC6EB;
}

body {
  font-size: 16px;
  line-height: 30px;
  font-family: 'Poppins', sans-serif;
  color: #FFFFFF;
  background: #324F67;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  scroll-behavior: smooth;
  font-weight: 300;
  margin: 0px;
}

.body-wrapper {
  overflow: hidden;
}

h1, h2, h3{
  font-family: 'Philosopher', sans-serif;
}

h1 {
  font-weight: bold;
  font-size: 78px;
  line-height: 70px;
  color: var(--heading-primary-color);
  margin: 20px 0;
  position: relative;
  z-index: 1000;
}

h1 span{
  font-weight: normal;
  font-size: 48px;
  line-height: 70px;
}

@media only screen and (max-width: 1095px) {
  .inner-table td {
    font-size: 14px !important;
    line-height: 1;
  }
}

@media only screen and (max-width: 867px) {
  h1{
    font-size: 44px;
    line-height: 50px;
  }
  h1 span{
    font-size: 24px;
    line-height: 35px;
  }
}
h2 {
  font-weight: normal;
  font-size: 60px;
  line-height: 70px;
  color: var(--heading-primary-color);
  margin: 20px 0;
}
@media only screen and (max-width: 867px) {
  h2{
    font-size: 38px;
    line-height: 44px;
  }
}
h3 {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 70px;
  color: var(--heading-primary-color);
  margin: 20px 0;
}
@media only screen and (max-width: 867px) {
  h3{
    font-size: 26px;
    line-height: 32px;
  }
}

p {
  font-size: 16px;
  font-weight: 300;
  line-height: 30px;
  text-align: left;
  color: var(--text-primary-color);
  padding: 20px 0;
}
p+p{
  padding: 10px 0;
}
@media only screen and (max-width: 867px) {
  p{
    line-height: 24px;
  }
}
a {
  text-decoration: none;
  color: var(--link-primary-color);
}
.scroll-to-link * {
  pointer-events: none;
}
.text-center {
  text-align: center;
}
.text-white {
  color: #FFFFFF
}
.text-orange{
  color: var(--button-primary-color)
}
.pull-right{
  float: right;
}
.grid {
  display: grid;
  grid-gap: 20px;
  gap: 20px;
}

/* Banner */
.banner{
  background: #FFFFFF;
  box-shadow: 0px 4px 105px rgba(34, 48, 74, 0.2);
  border-radius: 0px 0px 10px 10px;
  padding: 10px 20px 10px 10px;
  display: grid;
  gap: 30px;
  align-items: center; 
  grid-template-columns: 0.8fr 2fr 0.7fr;
  position: relative;
  z-index: 3;
}
.banner .logo-box{
  background: #EAF2F8;
  border-radius: 10px;
}
.banner .logo-box a{
  background: url(../image/domainer_logo.svg);
  background-repeat: no-repeat;
  background-position: center center;
  color: transparent;
  display: block;
  font-size: 0;
  padding: 20px 30px;
  height: 138px;
}
.banner .title{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 60px;
  color: #090909;
}
.banner .info{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #7D7F82;
  position: relative;
  margin-left: 15px;
  padding: 0;
}
.banner .info:before{
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 6px;
  height: 100%;
  margin-left: -15px;
  border-radius: 10px;
  background: #F27558;
}
.banner .domaner-btn{
  background: #F27558;
  transition: all 0.5s ease-in-out;
  border-radius: 10px;
  padding: 23px 5px;
  display: block;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;
  text-align: center;
  color: #000000;
}
.banner .domaner-btn:hover {
  background-color: rgb(249, 173, 141);
}
@media only screen and (max-width: 1090px) {
  .banner .title{
      font-size: 22px;
      line-height: 30px;
      margin-bottom: 10px;
  }
  .banner .info{
      font-size: 18px;
  }
  .banner .domaner-btn{
      font-size: 18px;
  }
  .banner .domaner-btn {
      font-size: 18px;
      line-height: 22px;
      display: table;
      margin: 0 auto;
      padding: 23px 30px;
  }
}
@media only screen and (max-width: 940px) {
  .banner{
      grid-template-columns: 1fr;
      padding: 10px;
  }
  .banner .logo-box a{
      background: url(../image/domainer_logo_mob.svg);
      background-repeat: no-repeat;
      background-position: center center;
      height: 66px;
  }
  .banner .title{
      line-height: 24px;
      text-align: center;
  }
  .banner .info{
      font-size: 16px;
      line-height: 20px;
      text-align: center;
  }
  .banner .info:before{
      content: none;
  }
}
@media only screen and (max-width: 440px) {
  .banner .domaner-btn {
      display: block;
      padding: 23px 0px;
  }
}

.col2 {
  grid-template-columns: 1fr 1fr;
}

.col2-1 {
  grid-template-columns: 2fr 1fr;
}

.col1-2 {
  grid-template-columns: 1fr 2fr;
}
.col3-4 {
  grid-template-columns: 3fr 4fr;
}
.col3-2 {
  grid-template-columns: 3fr 2fr;
}
.col1-3{
  grid-template-columns: 1fr 3fr;
}
@media only screen and (max-width: 867px) {
  .col2, .col2-1, .col1-2, .col3-4, .col3-2, .col1-3{
    grid-template-columns: 1fr;
  }
}
.container {
  margin-left: auto;
  margin-right: auto;
  max-width: 1149px;
  z-index: 1;
  position: relative;
}
@media only screen and (max-width: 1366px){
  .container {
    padding-left: 25px!important;
    padding-right: 25px!important;
  }
}

@media (min-width: 1300px){
  .container {
    min-width: 1300px;
  }
}

.mb40{
  margin-bottom: 40px;
}
.m-show{
  display: none;
}
/* Read more */
.m-hidden {
  display: initial;
}
.m-visible {
  display: none;
}
@media only screen and (max-width: 867px){
  .m-hidden, .mobile-detector {
    display: none;
  }
  .m-visible {
    display: initial;
  }
  .m-slide-down {
    height: 0;
    overflow: hidden;
    -moz-transition: height .5s;
    -ms-transition: height .5s;
    -o-transition: height .5s;
    -webkit-transition: height .5s;
    transition: height .5s;
  }
  .m-show {
    display: block;
  }
}

/* Button */
.btn-box{
  margin-top: 60px;
  margin-left: 50px;
}
.button {
  -webkit-backface-visibility: hidden;
  background-color: #ccc;
  background-image: linear-gradient(98.78deg, #FDA070 -4.95%, #9DBAFF 129.43%);
  box-shadow: 0px 14px 39px #2E4354;
  border-radius: 34px;
  display: inline-block;
  font-family: Poppins;
  font-weight: bold;
  font-size: 20px;
  line-height: 83px;
  text-align: center;
  text-transform: uppercase;
  color: #262626;
  padding: 0px 45px;
  white-space: nowrap;
  z-index: 1;
  position: relative;
}
.button span {
  position: relative;
  z-index: 3;
}
a.button{
  cursor: pointer;
}
.button:after {
  background-image: linear-gradient(277.57deg, #FDA070 5.51%, #9DBAFF 113.71%);
  border-radius: 34px;
  content: "";
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: opacity .5s ease-out;
  width: 100%;
  z-index: 2;
}
.button:hover{
  box-shadow: none;
  transition: all .5s;
}
.button:hover:after {
  opacity: 1;
}
.border{
  box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5);
  border: solid 3px transparent;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(98.78deg, #FDA070 -4.95%, #9DBAFF 129.43%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #314f67 inset;
  color: #D3DAE0;
  white-space: normal;
  font-size: 18px;
  line-height: 20px;
  width: 222px;
  padding: 25px 5px;
  transition: all 0.5s;
}

.tech-specs .slider-tabs li:hover .border, .tech-specs .slider-tabs li.active .border{
  box-shadow: none;
  color: white;
}
@media only screen and (max-width: 867px) {
  .btn-box{
    margin-left: 0;
    margin-top: 20px;
  }
  .btn-box .button{
    white-space: break-spaces;
    line-height: 22px;
    font-size: 18px;
    line-height: 27px;
    padding: 20px;
    width: 170px
  }
}
/* Header */

.site-logo {
  position: relative;
  z-index: 3;
  padding: 30px 0;
}

.site-logo .main-logo, .footer .main-logo {
  position: relative;
  display: inline-block;
  width: 400px;
  height: 43px;
  vertical-align: middle;
  font-size: 0;
  background: url(../image/logo.svg) no-repeat 0 0 / contain;
}
@media only screen and (max-width: 867px) {
  .site-logo .main-logo, .footer .main-logo{
    background-size: 335px !important;
    background-repeat: no-repeat;
    height: 37px;
    width: 269px!important;
  }

}
/* Intro */
.intro-primary{
  margin: 20px 0 40px;
}
.intro-primary .grid{
  position: relative;
}
.intro-primary .grid::before{
  content: "";
  position: absolute;
  background: #FFFFFF;
  opacity: 0.5;
  border-radius: 28px;
  width: 1px;
  height: 37%;
  left: 0;
  bottom: 0;
}
.intro-entry{
  width: 80%;
  margin-top: 27px;
  position: relative;
  z-index: 1000;
}
.intro-entry .m-slide-down{
  margin-left: 50px;
  margin-top: 20px;
  font-size: 15px;
  line-height: 30px;
}
.main-image-box{
  position: relative;
}
.main-image-box::before{
  content: '';
  height: 100%;
  width: 100%;
  background: #BEC6EB;
  opacity: 0.5;
  filter: blur(184px);
  bottom: 30%;
  position: absolute;
  right: -30%;
  z-index: 0;
  border-radius: 50%;
}
.main-image-box::after{
  content: '';
  height: 100%;
  width: 100%;
  background: #F4A37E;
  opacity: 0.3;
  filter: blur(184px);
  bottom: -40%;
  position: absolute;
  right: 30%;
  z-index: 0;
  border-radius: 50%;
}
.main-image-img{
    height: 600px;
}
.main-image-img::after{
  content: "";
  background-image: url(../image/bg_black_icon.webp), url(../image/bg_black_icon.webp), url(../image/favicon.png), url(../image/bg_white_icon.webp), url(../image/hero.webp) ;
  background-position: left bottom, 28% 0%, 90% 16%, 51% 80%, 0%;
  background-repeat: no-repeat;
  background-size: 100px, 180px, 43px, 110px, contain;
  position: absolute;
  width: 545px;
  height: inherit;
  top: 10%;
  right: 5%;
  z-index: 999;
}
.main-image-img::before{
  content: '';
  height: 100%;
  width: 100%;
  background: #F4A37E;
  opacity: 0.3;
  filter: blur(254px);
  bottom: -64%;
  position: absolute;
  right: 0;
  z-index: 0;
}
@media only screen and (max-width: 867px) {
  .intro-primary{
    margin-top: 0;
  }
  .intro-entry{
    width: 100%;
    z-index: 1;
    position: relative;
  }
  .intro-entry .m-slide-down{
    margin-left: 0;
  }
  .m-readmore-btn-wrapper{
    text-decoration-line: underline;
    color: #D9D9DA;
    display: block;
    margin-top: 15px;
    cursor: pointer;
  }
  .main-image-img::before, .intro-primary .grid::before{
    content: none;
  }
  .main-image-box{
    height: 130px;
  }
  .main-image-img{
    top: 0;
    right: 0;
    height: 360px;
    position: absolute;
    width: 100%;
    z-index: 0;
    margin-left: -25px;
    margin-right: -25px
  }
  .main-image-img::after{
    width: 100%;
    top: -50%;
    right: 0;
    background-image: url(../image/hero_mob.webp);
    background-position: right top;
    background-size: contain;
  }
}
@media only screen and (max-width: 1024px) {
  .main-image-box::before{
    right: 0;
  }
}
@-moz-document url-prefix() {
  .main-image-img::before {
    opacity: 0.2;
  }
}
@media (min-width: 1300px){
  .main-image-box::before {
    right: 0;
  }
}


/* what-is Section*/

.what-is{
  position: relative;
}
.line{
  height: 1px;
  width: 100%;
  background: #FFFFFF;
  opacity: 0.5;
  border-radius: 28px;
  position: relative;
  margin: 90px 0;
}
.line::after{
  content: "";
  height: 1px;
  width: 100%;
  background: #243747;
  opacity: 0.6;
  border-radius: 28px;
  position: absolute;
  top: 1px;
}
@media only screen and (max-width: 867px) {
  .line{
    margin: 35px 0;
  }
}
.what-is .bg-line, .when-to-use .bg-line {
  background-image: url(../image/bg_vertical.webp);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
}
.what-is.grid{
  gap: 70px;
}
.what-is p, .marketplaces p.text{
  width: 85%;
}
.what-is ul{
  display: flex;
  font-family: Philosopher;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  list-style: none;
  margin: 35px 0;
}
.what-is ul li {
  flex-basis: 46%;
  position: relative;
  z-index: 5;
  margin-left: 30px;
}
.what-is ul li::after {
    content: "";
    position: absolute;
    width: 65px;
    height: 65px;
    background: #2A3E4E;
    border-radius: 34px;
    left: -30px;
    top: -7px;
    z-index: -1;
}
@media only screen and (max-width: 867px) {
  .what-is .grid{
    gap: 0px;
  }
  .what-is p, .marketplaces p.text {
    width: 100%;
  }
  .what-is ul {
    display: block;
  }
  .what-is ul li+li{
    margin-top: 50px;
  }
}
/* Table */

.inner-table td[data-name="Price"] {
  width: 10%;
  padding-left: 33px;
}


/* TABLE  */
.table{
  position: relative;
}
.table::after, .table::before{
  content: '';
  position: absolute;
  border-radius: 50%;
  width: 30%;
  height: 50%;
  filter: blur(156px);
  top: 10%;
  margin: 0 auto;
}
.table::after{
  background: #BEC6EB;
  right: 30%;
  top: 0;
  opacity: 0.15;
}
.table::before{
  background: rgba(237, 182, 165, 1);
  left: 30%;
  opacity: 0.3;
}
.main-table{
  border: 0;
    border-collapse: separate;
    border-spacing: 0 10px;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    width: 100%;
    margin-top: 50px;
}
.main-table tbody tr {
  border-radius: 28px;
  margin: 15px 0;
  padding: 5px 5px 5px 20px;
}
.main-table td:first-child {
  border-bottom-left-radius: 28px;
  border-left-style: solid;
  border-top-left-radius: 28px;
}
.main-table td:last-child {
  border-bottom-right-radius: 28px;
  border-right-style: solid;
  border-top-right-radius: 28px;
}
.main-table td {
  background-color: rgba(36, 55, 71, 0.8);
  border-color: transparent;
  border-style: solid none;
  border-width: 1px;
  padding: 10px;
  position: relative;
  text-align: left;
}
.inner-table{
  border-spacing: 0 10px;
  width: 100%
}
.inner-table thead{
  display: none;
  height: 0;
  overflow: hidden;
  opacity: 0;
}
.inner-table td{
  background: transparent;
  min-width: 130px;
  width: 16.66%;
}
.inner-table td:first-child{
  border-radius: 28px;
  background: rgba(36, 55, 71, 0.6);
}
.inner-table td.expand-td{
  padding-left: 30px;
}
.inner-table td[data-name]::before {
  content: attr(data-name);
  font-weight: normal;
  font-size: 14px;
  line-height: 30px;
  color: #D9D9DA;
  text-transform: uppercase;
  position: absolute;
  top: 0;
}
.inner-table td:last-child[data-name]::before{
  position: relative;
  float: left;
  margin-bottom: 18px;
}
.t-logo .logo {
  background-position: 50%;
  background-repeat: no-repeat;
  font-size: 0;
  height: 90px;
  padding: 15px 0;
  width: 150px;
  margin: 0 auto;
  display: block;
}
.t-logo .logo.logo-playstation {
  background-image: url(../image/PlayStation_logo.svg);
}
.t-logo .logo.logo-meta {
  background-image: url(../image/meta_logo.webp);
}
.t-logo .logo.logo-steam {
  background-image: url(../image/Steam-logo.svg);
}
.t-logo .logo.logo-vive, .t-logo .logo.logo-cosmos{
  background-image: url(../image/htc.svg);
}

@media only screen and (max-width: 967px) {
  .inner-table td{
    min-width: fit-content;
  }
}
@media only screen and (max-width: 867px) {
  .table::after{
    left: 0;
    width: 100%;
    height: 100%;
  }
  .tabletabs.active{
    display: block;
    height: auto;
    opacity: 1;
    transition: opacity .3s linear;
  }
  .tabletabs {
    display: none;
    height: 0;
    opacity: 0;
}
  .main-table tbody tr{
    margin: 0;
    padding: 0;
  }
  
  .inner-table {
    border-spacing: 0;
  }
  .inner-table thead{
    display: none;
  }
  .inner-table tbody tr{
    display: table;
    border-radius: 20px;
    padding: 10px;
    width: 100%;
    max-width: -webkit-fill-available;
  }
  .inner-table td {
    border: none!important;
    position: relative;
    text-align: left!important;
    padding: 10px 5px!important;
    display: inline-table;
  }
  .inner-table td:nth-of-type(1){width: 100%;}
  .inner-table td:nth-of-type(2), .inner-table td:nth-of-type(3){width: 50%;margin-top: 30px;vertical-align:top;}
  .inner-table td:nth-of-type(4){float: left;width: 100%;margin-top: 30px;}
  .inner-table td:nth-of-type(5){width: 100%;margin-top: 30px;}
  .inner-table td:nth-of-type(6){width: 100%;display: block;margin: 0 auto;}

  table.inner-table td:not(:first-of-type, :last-of-type, :nth-of-type(4))::after{
      background-color: transparent;
  }
  .inner-table td[data-name]:not(:last-child)::before{
    top: -20px;
  }
  .inner-table td .button{
    float: unset;
    display: block;
    margin: 0 auto;
  }
  .inner-table td:last-child[data-name]::before {
    content: none;
  }
  .t-logo {
    border-radius: 25px;
    padding: 20px 10px;
    height: auto;
    width: 100%!important;
  }
  .t-logo .logo {
    display: block;
    height: auto;
    font-size: 0;
    text-indent: -4000px;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    max-width: 100%;
    margin: 0 auto;
    float: none;
  }
  .t-os{
    margin: inherit;
  }
  .t-rating {
    font-size: 14px;
    line-height: 21px;
    color: #2E2056;
  }
  .t-rating{
    justify-content: left
  }
}
@media only screen and (max-width: 300px){
  .inner-table td .button {
    padding: 0px;
    font-size: 14px;
  } 
}

/* td expand */
.t-expand{
  float: left;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  width: 100%;
  text-align: left;
}
.t-expand-title{
  font-size: 0;
  display: none;
}
.t-expand-body ul{
  display: flex;
  list-style: none;
}
.t-expand-body li+li:before{
  color: var(--link-primary-color);
  content: "\2022";
  margin: 0 15px;
}
@media only screen and (max-width: 867px) {
  .t-expand.collapsed .t-expand-body {
    height: 0;
    opacity: 0;
    overflow: hidden;
  }
  .t-expand.expanded .t-expand-body{
    height: auto;
    opacity: 1;
    transition: opacity 0.3s ease-out;
    padding-left: 10px;
  }
  .t-expand-title{
    cursor: pointer;
  }
  .t-expand-body ul {
    display: block;
  }
  .t-expand-body ul li{
    margin-left: 20px;
  }
  .t-expand-body ul li+li{
    margin-top: 20px;
  }
  .t-expand-body li:before{
    color: var(--link-primary-color);
    content: "\2022";
    margin: 0 15px;
    margin-left: -20px;
  }
  .t-expand-body li+li:before{
    margin-left: -20px;
  }
  .t-expand-title{
    position: relative;
    font-size: 14px;
    line-height: 30px;
    color: #D9D9DA;
    padding-left: 10px;
    text-transform: uppercase;
    display: block;
  }
  .t-expand-btn{
    position: absolute;
    right: 30px;
    top: 7px;
  }
  .t-expand-body-btn {
    float: right;
    height: 20px;
    position: relative;
    width: 30px;
}
  .t-expand-btn:after {
    border-left: 2px solid #FFFFFF;
    border-top: 2px solid #FFFFFF;
    content: "";
    height: 10px;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    transform: rotate(225deg);
    -ms-transform: rotate(225deg);
    -webkit-transform: rotate(225deg);
    -webkit-transition: -webkit-transform .5s ease-in-out;
    -ms-transition: -ms-transform .5s ease-in-out;
    transition: transform .5s ease-in-out;
    width: 10px;
  }
  .expanded .t-expand-btn:after {
    transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
}

/* When to use section */
.when-to-use{
  position: relative;
}
.when-to-use::before{
  content: '';
  background: var(--circle-orange-color);
  left: 0;
  bottom: -50%;
  opacity: 0.5;
  position: absolute;
  border-radius: 50%;
  width: 40%;
  height: 100%;
  filter: blur(286px);
}
@-moz-document url-prefix() {
  .when-to-use::before{
    opacity: 0.1;
  }
}
.when-to-use h2{
  color: #283B4D;
}
.when-to-use p{
  color: #324F67;
  width: 85%;
}
.when-to-use .inner{
  padding: 30px 15px 30px 0px;
}
.when-to-use .col1-3 {
  grid-template-columns: 30% 3fr;
  gap: 40px;
  background: rgba(252, 254, 255, 0.8);
  border-radius: 52px;
  margin-top: 100px;
}
.when-to-use .bg-line{
  background-image: url(../image/when_to_use_bg.webp);
  background-size: cover;
  border-radius: 30px;
}
@media only screen and (max-width: 867px) {
  .when-to-use::before{
    width: 80%;
  }
}
@media only screen and (max-width: 700px) {
  .when-to-use .col1-3 {
    grid-template-columns: 1fr;
  }
  .when-to-use .col1-3{
    gap: 0;
    overflow: hidden;
  }
  .when-to-use .bg-line{
    height: 205px;
    background-image: url(../image/bg_line_mobile.webp);
    background-size: cover;
    background-position: top;
  }
  .when-to-use .inner{
    padding: 25px;
  }
  .when-to-use .inner h2{
    font-size: 32px;
    line-height: 36px;
    margin: 10px 0 0 0;
  }
  .when-to-use p{
    width: 100%;
  }
}
/* Slider */
.slider-box{
  position: absolute;
  left: 50%;
  top: 30px;
}
.slider .mytabs{
  width: 100%;
}
.mytabs.active {
  display: block;
  height: auto;
  opacity: 1;
  transition: opacity .3s linear;
  float: left;
}
.mytabs {
  display: none;
  height: 0;
  opacity: 0;
} 
.marketplaces .mytabs.active+.mytabs{
  opacity: 0.6;
  display: block;
  height: auto;
}
.slider-nav{
  margin: 35px 25px 25px 150px;
  width: 140px;
}
.slider-nav ul, ul.slider-nav{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
}
.slider-nav ul li, ul.slider-nav li {
  background-color: #C4C4C4;
  border-radius: 12px;
  height: 14px;
  list-style-type: none;
  width: 14px;
  cursor: pointer;
}
.slider-nav ul li.active, ul.slider-nav  li.active{
  background-color: var(--button-primary-color);
}


@media only screen and (max-width: 500px) {
  .when-to-use .col1-3{
    border-radius: 40px !important;
  }
}

@media only screen and (max-width: 400px) {
  .when-to-use .col1-3{
    border-radius: 33px !important;
  }
}

@media only screen and (max-width: 867px) {
.slider-nav {
    margin: 15px;
    width: 100%;
    justify-content: center!important;
  }
  .slider-nav li+li{
    margin-left:15px;
  }
}
.marketplaces .slider{
  display: flex;
  gap: 40px;
}
.marketplaces{
  position: relative;
  margin-top: 90px;
  overflow: hidden;
}
.marketplaces .grid{
  margin-bottom: 120px;
}
.marketplaces .slider .mytabs{
  background: #BAB3D4;
  box-shadow: -3px 19px 55px rgba(82, 89, 95, 0.6);
  border-radius: 34px;
  padding: 15px 40px 30px 40px;
  width: 440px;
  height: 340px;
  z-index: 1;
}
.marketplaces .slider .mytabs.active{
  box-shadow: -3px 19px 55px rgba(82, 89, 95, 0.6);
}
.marketplaces .slider .mytabs p{
  font-size: 16px;
  line-height: 30px;
  color: #313131;
  padding: 10px 0;
}
.marketplaces .slider .mytabs h3{
  color: #313131;
  margin-bottom: 0;
  line-height: 40px;
}
@media only screen and (max-width: 867px) {
  .slider-box {
    position: relative;
    left: 0;
    top: 0;
  }
  .marketplaces{
    margin-top: 50px;
  }
  .marketplaces .grid{
    margin-bottom: 0;
    display: block;
  }
  .marketplaces .slider{
    gap: 20px;
    margin-top: 20px;
  }
  .marketplaces .slider .mytabs{
    width: auto;
    height: auto;
    padding: 10px 30px;
  }
  .marketplaces .slider-nav{
    margin-left: 20%;
    width: 140px;
    margin-top: 30px;
    margin-bottom: 30px;
  }
}
@media only screen and (max-width: 867px) and (min-width: 320px) {
  .marketplaces .slider .mytabs{
    min-width: 280px;
    max-width: 440px;
  }
}
/* Tech specs section */
.tech-specs{
  position: relative;
}
.tech-specs::after{
  content: '';
  background: var(--circle-orange-color);
  right: 0;
  bottom: -100%;
  opacity: 0.7;
  position: absolute;
  border-radius: 50%;
  width: 40%;
  height: 80%;
  filter: blur(296px);
}
@-moz-document url-prefix() {
  .tech-specs::after {
    opacity: 0.2;
    width: 30%;
  }
}
.tech-specs .grid{
  grid-template-columns: 3fr 2fr;
  justify-content: center;
  gap: 40px;
}
.tech-box{
  background: rgba(252, 254, 255, 0.8);
  border-radius: 28px;
}
.tech-box .text{
  padding: 30px 30px 30px 0;
}
.tech-box .text p{
  font-size: 16px;
  line-height: 42px;
  color: #324F67;
}
.img-box{
  border-radius: 27px;
  background-position: center; 
  background-repeat: no-repeat;
  background-size: 75%; 
}
#tab-CUH-ZVR2 .img-box{
  background-image: url("../image/CUH-ZVR2.webp"); 
  background-color: white;
}
#tab-Oculus .img-box{
  background-image: url("../image/Oculus_Quest.webp"); 
  background-color: #e6e5ec;
}
#tab-Valve .img-box{
  background-image: url("../image/Valve_Index_VR.webp"); 
  background-color: black;
}
#tab-HTC-pro .img-box{
  background-image: url("../image/vive-pro.webp"); 
  background-color: #181614;
}
#tab-HTC .img-box{
  background-image: url("../image/Vive-Cosmos-Elite.webp"); 
  background-color: black;
}
.tech-specs .slider-tabs{
  list-style: none;
  display: flex;
  /* justify-content: center; */
  flex-wrap: wrap;
  gap: 30px;
}

@media only screen and (max-width: 1300px) {
  .tech-specs .slider-tabs{ 
    justify-content: center;
  }
}

.tech-specs .slider-tabs li{
  cursor: pointer;
}
.tech-specs .slider-tabs li.active, .tech-specs .slider-tabs li:hover{
  border-radius: 34px;
  box-shadow: 0px 14px 39px #2e4354;
}
@media only screen and (max-width: 867px) {

  .site-logo {
    display: flex;
    justify-content: center;
  }

  

  .tech-specs::after{
    bottom: -140%;
    width: 50%;
    height: 50%;
  }
  .tech-specs .grid{
    grid-template-columns: 1fr!important;
  }
  .tech-specs .tech-box{
    gap: 0;
  }
  .tech-box .text {
    padding: 0 30px;
}
  .img-box{
    height: 192px;
    background-size: contain;
  }
  .tech-specs .tabs{
    grid-column-start: 1;
    grid-row-start: 1;
    display: block;
    overflow: hidden;
    margin: 0 -25px;
  }
  .tech-specs .slider-tabs{
    min-width: auto;
    width: 250px;
    flex-wrap: unset;
  }
  .tech-specs .slider-tabs li{
    transition: margin .2s linear;
  }
  .tech-specs .slider-tabs li:first-child{
    margin-left: 10px;
  }
  .tech-specs .slider-tabs li.active, .tech-specs .slider-tabs li:hover{
    height: fit-content;
  }
}


/* FAQ */
.faq-listing{
  margin-top: 40px;
}
.faq{
  background: rgba(36, 55, 71, 0.6);
  border-radius: 28px;
  padding: 5px 50px 15px 50px;
  margin-bottom: 25px;
}
.faq h3{
  font-family: Philosopher;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  margin-bottom: 0;
}
.faq h3::before{
  content: "\0023AF";
  margin-right: 10px;
  font-size: 16px;
  display: inline-block;
  position: relative;
  top: -6px;
}
@media only screen and (max-width: 867px) {
  .faq-listing{
    margin-top: 20px;
  }
  .faq{
    padding: 5px 30px 15px 30px;
  }
  .faq a{
    text-decoration-line: underline;
  }
  .faq h3::before{
    content: none;
  }
}

/* About us */
.contacts{
  position: relative;
  margin: 75px 0;
}
.contacts::after{
  content: '';
  background: var(--circle-blue-color);
  left: 25%;
  top: 10%;
  opacity: 0.8;
  position: absolute;
  border-radius: 50%;
  width: 30%;
  height: 50%;
  filter: blur(156px);
}
@-moz-document url-prefix() {
  .contacts::after{
    opacity: 0.3;
    filter: blur(300px);
  }
  .contacts::before{
    opacity: 0.2!important;
    width: 30%!important;
    width: 30%!important;
  }
}
.contacts::before{
  content: '';
  background: var(--circle-orange-color);
  left: 40%;
  top: 25%;
  opacity: 0.9;
  position: absolute;
  border-radius: 50%;
  width: 20%;
  height: 20%;
  filter: blur(106px);
}
.btn-contact{
  margin: 30px auto;
  display: table;
}
.btn-contact .button{
  cursor: pointer
}


@media only screen and (max-width: 867px) {
  .contacts::after{
    width: 60%;
    left: 0;
  }
}
/* Footer */
.footer-row{
  background: rgba(36, 55, 71, 0.6);
  padding: 20px 35px;
  border-top-left-radius: 28px;
  border-top-right-radius: 28px;
}
.footer-inner{
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #D9D9DA;
}
.footer .main-logo{
  width: 380px;
}
@media only screen and (max-width: 730px) {
  .footer-inner {
    display: grid;
    justify-content: center;
    text-align: center;
  }
  .footer .main-logo{
    background: none;
    font-family: Philosopher;
    line-height: 32px;
    font-weight: bold;
    font-size: 22px;
    color: rgba(255, 255, 255, 0.8);
  }
  .footer span{
    display: block;
  }
}




@mixin transition($params) {
  -webkit-transition: $params;
     -moz-transition: $params;
   -khtml-transition: $params;
       -o-transition: $params;
          transition: $params;
}

@mixin rotate($params) {
  -webkit-transform: rotate($params);
     -moz-transform: rotate($params);
   -khtml-transform: rotate($params);
       -o-transform: rotate($params);
          transform: rotate($params);
}